<script setup lang="ts">
import Banking from '~/components/banking/banking.vue';
import { useAuthStore } from '~/stores/auth.store';
import { useSiteStore } from '~/stores/site.store';
import { useRgStore } from '~/stores/responsibleGaming.store';
import { LockIcon } from '../../design-system/icons/index';
import ExternalIcon from '~/components/design-system/icons/ExternalIcon.vue';
import { ChevronRightIcon } from '@heroicons/vue/24/outline';

const authStore = useAuthStore();
const site = useSiteStore();
const rg = useRgStore();

const liveChatClicked = () => {
  site.toggleFeature({ feature: 'displayLiveChat' });
};

onBeforeMount(() => {
  if (
    authStore.getComplianceStatus < 1 &&
    site.getFeatureFlags.get('myaccount.enablecompliance')
  ) {
    authStore.setAccountModalPage('document-verification');
  }
});
</script>
<template>
  <div>
    <LazyUserAccountSettingsHeader
      :title="`${$t('deposit')} (${authStore.currentUser.primaryMobileNumber})`"
      show-balance-mobile
      return-route="teller"
    />
    <div class="px-3 hidden md:block">
      <UserBalances
        class="w-full"
        display-balance-toggle
        display-cash-balance
        display-bonus-balance
        display-total-balance
        stretch
        account-options-modal-style
        single-line
      />
    </div>
    <Banking v-if="rg.allowWager" type="Deposits" />
    <div v-else>
      <div
        class="pt-3 text-base-priority bg-base flex flex-col justify-center items-center flex-wrap mt-3"
      >
        <div class="flex flex-row items-center">
          <div class="border-gold-500 border-4 rounded-full p-2 flex">
            <LockIcon class="w-10 h-10 text-gold-500" />
          </div>
          <span class="ml-2">{{ $t('account-restricted') }}</span>
        </div>
        <p class="mt-4 line-height-3 mb-2 text-center text-sm">
          {{ $t('account-lock-restricted') }}
        </p>
        <p
          class="text-center mb-4 line-height-3 text-sm"
          v-html="$t('only-permitted-access-withdrawal')"
        />
        <div
          class="flex justify-between bg-light-200 dark:bg-dark-800 rounded-md mb-4 pl-3 cursor-pointer py-1"
          @click="liveChatClicked()"
        >
          <div class="flex items-center mt-0.5 mb-0.5">
            <ExternalIcon class="w-6 mt-1 mr-3" icon-name="chat-support" />
            <p class="text-sm my-0 font-bold mr-1 line-height-1">
              {{ $t('chat') }}
            </p>
            <p class="text-sm my-0 line-height-1">
              {{ $t('to-live-support') }}
            </p>
          </div>
          <ChevronRightIcon
            class="w-4 dark:text-light-50 text-dark-700 mr-2 stroke-2 stroke-current"
          />
        </div>
      </div>
    </div>
  </div>
</template>
